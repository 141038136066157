<template>
  <div class="flex-column">
    <!--banner-->
    <div class="banner-box">
      <div class="w1320 flex-column flex-center h100 banner-custom">
        <span class="banner-title animated fadeInDown">合气网</span>
        <span class="banner-shuoming animated fadeInDown delay-1s"
          >合气天然气交易平台是国内第三家天然气线上交易平台，底部依托阿里云技术支撑,结合中石油、中石化、中海油的优质气源
          顶部依托山东 港口的输出窗口，做到上游有保证，中游有依托，下游
          有支撑，打通天然气的全链路交易通道。</span
        >
      </div>
    </div>
    <!--    应用案例-->
    <div class="yingyong-box">
      <div class="w1320 flex-column flex-center h100">
        <span class="yingyong-title">应用案例</span>
        <el-carousel :interval="4000" type="card" height="422px">
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img :src="item.src" />
            <div class="swiper-box-box flex-column flex-center">
              <span>{{ item.title }}</span>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <!--    产品理念-->
    <div class="w1320 flex-column custom-linian">
      <span>产品理念</span>
      <div class="flex-row flex-between custom-linian-box">
        <div class="flex-column flex-align custom-item">
          <div class="waiquan flex-column flex-align flex-center">
            <div class="lansequan flex-column flex-align flex-center">
              <img
                src="https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/pic_chuangxin.png"
              />
            </div>
          </div>
          <span>创新</span>
          <span>打造山东首个云上<br />全链路天然气交易平台</span>
        </div>

        <div class="flex-column flex-align custom-item">
          <div class="waiquan flex-column flex-align flex-center">
            <div class="lansequan flex-column flex-align flex-center">
              <img
                src="https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/pic_wending.png"
              />
            </div>
          </div>
          <span>稳定</span>
          <span>背靠山东港口集团<br />确保优质以及一手的气源</span>
        </div>

        <div class="flex-column flex-align custom-item">
          <div class="waiquan flex-column flex-align flex-center">
            <div class="lansequan flex-column flex-align flex-center">
              <img
                src="https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/pic_anquan.png"
              />
            </div>
          </div>
          <span>安全</span>
          <span
            >整合多家大型贸易商<br />中海油、中石油、中石化的<br />线上线下资源</span
          >
        </div>
      </div>
    </div>

    <!--    解决方案-->
    <div class="jiejuefangan">
      <div class="w1320 h100 flex-column flex-center flex-align custom-jiejue">
        <span>解决方案</span>
        <div class="flex-row">
          <img
            src="https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/pic_jiejuefangan.png"
          />
          <div class="shuoming flex-column flex-align flex-center">
            <span>
              打造国内一流大宗天然气交易平台，整合线上线下天然气资源，打通天然气交易全链路。交易中心将推动天然气供应链区域融合发展。交易中心将形成强大网络效应。京津冀及北方地区天然气市场参与者通过交易活动降低成本、减少议价，参考价格、标准化合同可节约交易时间和提高资源配置效率，促进市场多元化。
            </span>
          </div>
        </div>
      </div>
    </div>

    <!--    业务介绍-->
    <div class="w1320 flex-column flex-align custom-yewu">
      <span>业务介绍</span>
      <div class="flex-row flex-between">
        <div
          class="flex-column flex-align custom-yewu-item"
          v-for="(item, index) in yewuList"
          :key="index"
        >
          <div class="img-box">
            <img :src="item.src" />
          </div>

          <span>{{ item.title }}</span>
          <span v-html="item.shuoming"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "heqiIndex",
  data() {
    return {
      bannerList: [
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/pic_banner1.png",
          title: "合气网首页，个人中心，专员页面",
        },
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/jihuatianbao@2x.png",
          title: "合气网后台管理系统 — 计划填报",
        },
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/gonghuoshang@2x.png",
          title: "合气网后台管理系统 — 供货商产品",
        },
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/tuikuan@2x.png",
          title: "合气网后台管理系统 — 退款管理",
        },
      ],
      yewuList: [
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/pic_xiaoshouzhanlue.png",
          title: "销售战略",
          shuoming:
            "销售总监制定通过后台制定<br>不同区域<br>不同时间的销售战略",
        },
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/pic_xiaoshoujihua.png",
          title: "销售计划",
          shuoming: "销售专员通过市场情况<br>提报不同的销售计划",
        },
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/pic_caigoushang.png",
          title: "采购商画像",
          shuoming:
            "根据大数据分析，生成<br>不同采购商的企业画像<br>做到精准服务",
        },
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/pic_yushou.png",
          title: "预售/抢购",
          shuoming: "打造山东首个云上<br>全链路天然气交易平台",
        },
      ],
    };
  },
  mounted() {
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0);
    });
  },
  methods: {
    goAnchor(selector) {
      document.querySelector(selector).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.w1320 {
  width: 1320px;
}

.banner-box {
  height: 562px;
  background: url("https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/banner.png")
    no-repeat center center;
  background-size: auto 100%;
}

.banner-title {
  font-size: 40px;
  line-height: 40px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 56px;
}

.banner-shuoming {
  font-size: 24px;
  line-height: 48px;
  font-weight: 400;
  color: #ffffff;
}

.banner-custom span {
  text-align: left;
}

.yingyong-box {
  height: 810px;
  background: url("https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/bg_yyal.png")
    no-repeat center center;
  background-size: auto 100%;
}

.yingyong-title {
  font-size: 44px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 94px;
}

/deep/ .el-carousel__indicator--horizontal .el-carousel__button {
  margin-top: 26px;
  background-color: #d2d2d2;
  width: 86px;
  height: 5px;
  opacity: 1;
}

/deep/ .el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 86px;
  height: 5px;
  background-color: #ffc244;
  opacity: 1;
}

.custom-linian {
  padding: 108px 0 131px 0;
}

.custom-linian > span {
  font-size: 44px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 50px;
}

.waiquan {
  width: 188px;
  height: 188px;
  border-radius: 50%;
  border: 1px dashed #3485ee;
  transition: all 0.3s ease-out;
}

.lansequan {
  width: 132px;
  height: 132px;
  border-radius: 50%;
  background: #3485ee;
}

.custom-item > span:nth-of-type(1) {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 22px;
  margin-top: 50px;
}

.custom-item > span:nth-of-type(2) {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}

.custom-item:nth-of-type(1) {
  max-width: 300px;
}

.custom-item:nth-of-type(2) {
  max-width: 300px;
  margin-left: 100px;
  margin-right: 100px;
}

.custom-item:nth-of-type(3) {
  max-width: 300px;
  /*margin-right: 0px;*/
}

.custom-linian-box {
  padding: 0 54px;
}
.jiejuefangan {
  height: 884px;
  background: url("https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/bg_jiejuefangan.png")
    no-repeat center center;
  background-size: auto 100%;
}

.custom-jiejue > span {
  font-size: 44px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 85px;
}

.shuoming {
  width: 342px;
  height: 533px;
  background: rgba(9, 22, 40, 0.27);
  margin-left: 23px;
}

.shuoming > span {
  display: block;
  width: 286px;
  height: 389px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  text-align: justify;
}

.custom-yewu {
  padding: 134px 0 79px 0;
}

.custom-yewu > span {
  font-size: 44px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 80px;
}

.custom-yewu > div {
  width: 100%;
}

.custom-yewu-item > span:nth-of-type(1) {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin: 41px 0 22px 0;
}

.custom-yewu-item > span:nth-of-type(2) {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}

.waiquan:hover {
  animation: fanzhuan 0.4s forwards;
}

@keyframes fanzhuan {
  0% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0);
  }
}

.img-box {
  width: 317px;
  height: 202px;
  overflow: hidden;
}

.img-box > img {
  transition: all 0.3s ease-out;
}

.img-box > img:hover {
  transform: scale(1.2);
}
.swiper-box-box {
  height: 90px;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}

.swiper-box-box > span {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .shuoming {
    /*display: block;*/
    width: 300px;
    /*height: 533px;*/
  }

  .custom-jiejue > div {
    transform: scale(0.9);
    /*width: 600px;
    display: block;
    height: auto;*/
  }

  .custom-yewu > div > div {
    transform: scale(0.9);
  }
}
</style>
